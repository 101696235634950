/**
 * @module SiteMenu
 * @description Site Menu
 */
import {
  useEffect, useContext, useRef
} from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';
import PropTypes from 'prop-types';
import { useSiteNavigation } from '@/hooks/useSiteNavigation';
import { stringify } from '@/js/utils/filters';
import { fastly } from '@/js/utils/helpers';
import { FuelContext } from '@/providers/fuel-provider';
import { trackEvent } from '@/js/helpers/tracking';
import { addPageAction } from '@/js/utils/new-relic';

// Components
import StickyNav from '@/blocks/StickyNav';
import { MonarchPreampPlacement } from '@redventures/cohesion-utils-react';
import { getBaseAssetName } from '@/js/utils/helpers';

/**
 * @function SiteNavigation
 * @description
 */
export const SiteNavigation = ( { ...props } ) => {
  const {
    menus, headerLinks
  } = props;

  const { init } = useSiteNavigation();

  const { clickThrough } = useContext( FuelContext );

  const didInitRef = useRef( false );

  /**
   * @function handleClick
   * @description fire off tagular events
   */
  const handleClick = ( event ) => {
    const { target } = event;
    const payload = JSON.parse( target.getAttribute( 'data-payload' ) );
    const { text, actionOutcome, position } = payload;

    const data = {
      elementType: 'BUTTON',
      location: 'GLOBAL',
      position,
      text,
      actionOutcome
    };

    trackEvent( { action: 'elementClicked', data, event } );
  };

  /**
   * @function handleBuyClick
   * @description fire New Relic cart entrance event
   */
  const handleBuyClick = ( event ) => {
    addPageAction( 'cartEntrance', { method: 'MASTHEAD' } );
    handleClick( event );
  };

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    if ( didInitRef.current === false ) {
      didInitRef.current = true;
      init();
    }
  }, [ ] ); /* eslint-disable-line react-hooks/exhaustive-deps */

  return <>
  <MonarchPreampPlacement placement="4Bvc1sXDyK0dCyVA5tRvmA" componentId={ getBaseAssetName }>
    <StickyNav />
  </MonarchPreampPlacement>
    <div className="l-container">

      <div className="site-header-primary">
        <div className="navigation-toggle-wrap">
          <button className="navigation-toggle" type="button" role="button">
            <span className="navigation-toggle-title screen-reader-text">Open Menu</span>
            <span className="navigation-toggle-icon">
              <span className="icon icon-bar"></span>
            </span>
          </button>{ /* <!-- .navigation-toggle--> */ }
        </div>


        <div className="site-branding">
          <h1 className="site-title">
            <span className="screen-reader-text">Frontier Communications</span>
            <Link
              href="/"
              className="site-brand"
              onClick={ handleClick }
              data-payload={ stringify( {
                text: 'Frontier Logo',
                position: 'NAVIGATION',
                actionOutcome: 'INTERALLINK'
              } ) }
              rel="home">

              <Image
                priority
                src="/migration/site-logo-rebrand.svg"
                alt="Frontier Communications Logo"
                width={ 36 }
                height={ 36 }
                layout="responsive"
                className="site-logo"
                loading="eager"
                loader={ fastly }
              />

            </Link>
          </h1>
        </div>{ /* <!-- .site-branding --> */ }
      </div>{ /* <!-- .site-header-primary --> */ }

      <div className="navigation-collapse">
        <div id="navigation" className="navigation-area">

          <div className="navigation-area-primary">
            <nav className="navigation site-navigation" role="navigation" aria-label="Site Navigation">
              <ul className="site-menu menu">
                { menus.map( ( menu, index ) => (
                    <li key={ index } className={ `menu-item ${ menu.links.length ? 'menu-item-has-children' : '' }` }>

                    { menu.links.length ?
                      <>
                        <button
                          className="btn"
                          type="button"
                          onClick={ handleClick }
                          data-payload={ stringify( {
                            text: menu.title,
                            actionOutcome: 'INTERALLINK'
                          } ) }
                          role="button">{ menu.title }</button>
                      </> :
                      <>
                        <Link
                          href={ menu.href }
                          target={ menu.target }
                          onClick={ handleClick }
                          data-payload={ stringify( {
                            text: menu.title,
                            position: 'NAVIGATION',
                            actionOutcome: 'INTERALLINK'
                          } ) }>
                          { menu.title }
                        </Link>
                      </>
                    }


                      <div className="submenu-wrap">
                        <ul className="submenu menu">
                          { menu.links.map( ( link, index1 ) => (
                              <li key={ index1 }>
                                <Link
                                  href={ link.href }
                                  target={ link.target }
                                  onClick={ handleClick }
                                  data-payload={ stringify( {
                                    text: link.title,
                                    position: 'NAVIGATION',
                                    actionOutcome: 'INTERALLINK'
                                  } ) }>
                                  { link.title }
                                </Link>

                                { link.links &&
                                  <ul>
                                    { link.links.map( ( link1, index2 ) => (
                                        <li key={ index2 }>
                                          <Link
                                            href={ link1.href }
                                            target={ link1.target }
                                            onClick={ handleClick }
                                            data-payload={ stringify( {
                                              text: link1.title,
                                              position: 'NAVIGATION',
                                              actionOutcome: 'INTERALLINK'
                                            } ) }>
                                            { link1.title }
                                          </Link>
                                        </li>
                                    ) ) }
                                  </ul>
                                }
                              </li>
                          ) ) } { /* <!-- .menu-item --> */ }
                        </ul>
                      </div>
                    </li>
                ) ) }
              </ul>
            </nav>{ /* <!-- .site-navigation --> */ }
          </div>{ /* <!-- .navigation-area-primary --> */ }

          <div className="navigation-area-secondary">
            <ul className="menu header-menu">
              { headerLinks.map( ( link, index ) => (
                <li key={ index }>
                  <Link
                    href={ link.href || clickThrough }
                    target={ link.target }
                    onClick={ handleClick }
                    data-payload={ stringify( {
                      text: link.title,
                      position: 'NAVIGATION',
                      actionOutcome: 'INTERALLINK'
                    } ) }>

                    <span className="display-none-md">{ link.title }</span> <span className={ `icon ${ link.icon }` }></span>

                  </Link>
                </li>
              ) ) }
            </ul>
          </div>{ /* <!-- .navigation-area-secondary --> */ }

          <div className="navigation-area-tertiary">
            <ul className="menu complementary-menu complementary-menu--secondary">
              <li>
                <Link
                  href={ clickThrough }
                  onClick={ handleBuyClick }
                  data-payload={ stringify( {
                    position: 'MASTHEAD',
                    text: 'Buy',
                    actionOutcome: 'INTERALLINK'
                  } ) }>
                  Buy
                </Link>
              </li>
            </ul>
          </div>{ /* <!-- .navigation-area-tertiary --> */ }
        </div>{ /* <!-- .navigation-area --> */ }
      </div>{ /* <!-- .navigation-collapse --> */ }
    </div>
  </>;
};

SiteNavigation.defaultProps = {
  menus: [
    {
      title: 'Shop Plans',
      href: '/plans-pricing',
      links: []
    },
    {
      title: 'Business',
      href: 'https://business.frontier.com/',
      links: []
    },
    {
      title: 'Current Customers',
      href: 'https://frontier.com/helpcenter',
      links: []
    }
  ],
  headerLinks: [
    { icon: 'icon-shopping-cart', href: null, title: 'Buy' }
  ]
};

SiteNavigation.propTypes = {
  menus: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape( {
          href: PropTypes.string,
          title: PropTypes.string,
          target: PropTypes.string
        } )
      )
    } )
  ),
  headerLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string
    } )
  )
};

export default SiteNavigation;
